<template>
  <div>
    <h1>Three great ways to sign up to GranjaEU</h1>
    <v-form v-model="signupForm" ref="signupForm">
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>Product</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title>Product 1</v-card-title>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card>
                    <v-card-title>Product 2</v-card-title>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card>
                    <v-card-title>Product 3</v-card-title>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title>Early Access Customers</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <p>Enter your early access code as received by email.</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field data-cy="earlyAccessCode" label="Early Access Code" required
                            v-model="earlyAccessCode" :rules="earlyAccessCodeRoles"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col><v-alert v-if="error" type="error">{{ error }}</v-alert></v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn data-cy="buy" color="primary" @click="buyProduct">Subscribe</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'SignupPage',

  data: () => ({
    signupForm: false,
    error: '',
    earlyAccessCode: '',
    earlyAccessCodeRoles: [
      v => !!v || 'Early Access Code is required'
    ]
  }),

  methods: {
    buyProduct () {
      if (this.$refs.signupForm.validate()) {
        fetch('/api/signup/purchase', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            earlyAccessCode: this.earlyAccessCode
          })
        })
          .then(response => {
            if (response.status === 200) {
              this.$router.push({ name: 'SignupAccount' })
            } else {
              throw new Error(response.statusText)
            }
          })
          .catch(error => {
            console.log(error.message)
            this.error = error.message
          })
      }
    }
  }
}
</script>
